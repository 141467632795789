// extracted by mini-css-extract-plugin
export var approachInfoRoundBlock__between = "c5_xT";
export var approachInfoRoundBlock__card = "c5_xS";
export var approachInfoRoundBlock__contentFive = "c5_x3";
export var approachInfoRoundBlock__contentFour = "c5_x2";
export var approachInfoRoundBlock__contentOne = "c5_xZ";
export var approachInfoRoundBlock__contentSix = "c5_x4";
export var approachInfoRoundBlock__contentThree = "c5_x1";
export var approachInfoRoundBlock__contentTwo = "c5_x0";
export var approachInfoRoundBlock__description = "c5_xY";
export var approachInfoRoundBlock__icon = "c5_xW";
export var approachInfoRoundBlock__imageDesktop = "c5_xV";
export var approachInfoRoundBlock__title = "c5_xX";