// extracted by mini-css-extract-plugin
export var toolsHelpingUsSection__image = "dd_yY";
export var toolsHelpingUsSection__logo = "dd_yZ";
export var toolsHelpingUsSection__techStack = "dd_y1";
export var toolsHelpingUsSection__techStackTitle = "dd_y0";
export var toolsHelpingUsSection__techStackWrapper = "dd_y9";
export var toolsHelpingUsSection__techStackWrapper_axure = "dd_zh";
export var toolsHelpingUsSection__techStackWrapper_figma = "dd_zj";
export var toolsHelpingUsSection__techStackWrapper_github = "dd_zf";
export var toolsHelpingUsSection__techStackWrapper_gitlab = "dd_zd";
export var toolsHelpingUsSection__techStackWrapper_jira = "dd_zc";
export var toolsHelpingUsSection__techStackWrapper_slack = "dd_zg";
export var toolsHelpingUsSection__techStackWrapper_trello = "dd_zb";
export var toolsHelpingUsSection__techStack_axure = "dd_y3";
export var toolsHelpingUsSection__techStack_figma = "dd_y5";
export var toolsHelpingUsSection__techStack_github = "dd_y4";
export var toolsHelpingUsSection__techStack_gitlab = "dd_y8";
export var toolsHelpingUsSection__techStack_jira = "dd_y6";
export var toolsHelpingUsSection__techStack_slack = "dd_y2";
export var toolsHelpingUsSection__techStack_trello = "dd_y7";
export var toolsHelpingUsSection__wrapper = "dd_yX";