import { SuccessStoriesSliderMock } from '~/types/mock'

const SuccessStoriesSlider: SuccessStoriesSliderMock[] = [
  {
    title: 'Auto Parts Marketplace',
    url: 'auto-parts-marketplace',
    color: 'ifndColor',
    image: 'previewIfnd',
  },
  {
    title: 'Ammo Sales Platform',
    url: 'marketplace-platform-for-online-firearm-sales',
    color: 'ammoColor',
    position: 'left',
    image: 'previewDetroitAmmo',
  },
  {
    title: 'Online domain name trading marketplace platform',
    url: 'marketplace-platform-for-trading-domain-names',
    color: 'danColor',
    position: 'right',
    image: 'previewDan',
  },
  {
    title: 'Intelligent Job Match System',
    url: 'intelligent-job-matching-service',
    color: 'wownerColor',
    image: 'previewWowner',
  },
  {
    title: 'Multi-vendor vehicle marketplace',
    url: 'multi-vendor-vehicle-marketplace',
    color: 'africarColor',
    image: 'previewAfricar',
  },
  {
    title: 'SaaS platform for bakery business',
    url: 'saas-platform-for-bakery-business',
    color: 'cakerColor',
    image: 'previewCaker',
  },
  {
    title: 'Insurance Progressive Web App',
    url: 'insurance-progressive-web-application',
    color: 'babelColor',
    image: 'previewBabel',
  },
  {
    title: 'Online marketplace for boats',
    url: 'online-marketplace-for-boats',
    color: 'tradeaboatColor',
    image: 'previewTradeaboat',
  },
  {
    title: 'Online Service Marketplace',
    url: 'kids-activities-service-marketplace',
    color: 'pmkColor',
    image: 'previewPmk',
  },
  {
    title: 'Recreational vehicles marketplace platform',
    url: 'online-marketplace-for-caravans',
    color: 'tradervsColor',
    image: 'previewTradervs',
  },
  {
    title: 'Online news portal DUMA™',
    url: 'news-aggregator',
    color: 'dumaColor',
    image: 'previewDuma',
  },
  {
    title: 'Time tracking software',
    url: 'timebot-for-slack',
    color: 'timebotColor',
    position: 'left',
    image: 'previewTimebot',
  },
  {
    title: 'Fitness Progressive Web Application',
    url: 'fitness-progressive-web-application',
    color: 'impactColor',
    image: 'previewImpact',
  },
  {
    title: 'Custom E-commerce Solution',
    url: 'custom-multi-vendor-marketplace-platform',
    color: 'malleniColor',
    image: 'previewMalleni',
  },
  {
    title: 'Real Estate Portal',
    url: 'saas-real-estate-platform',
    color: 'variaColor',
    image: 'previewVaria',
  },
  {
    title: 'Online marketplace for selling collectibles',
    url: 'online-marketplace-for-collectibles',
    color: 'chatticColor',
    position: 'left',
    image: 'previewChattic',
  },
  {
    title: 'Accommodation Search Website',
    url: 'accommodation-search-website-mvp',
    color: 'xpcColor',
    position: 'right',
    image: 'previewXpc',
  },
  {
    title: 'Dental Imaging Management System',
    url: 'dental-imaging-management-system',
    color: 'refColor',
    image: 'previewRef',
  },
  {
    title: 'Recruitment Progressive Web Application',
    url: 'recruitment-progressive-web-application',
    color: 'rewColor',
    image: 'previewRew',
  },
  {
    title: 'Online travel marketplace',
    url: 'travel-management-platform',
    color: 'ixColor',
    image: 'previewIX',
  },
  {
    title: 'Online collaboration marketplace',
    url: 'collaboration-marketplace-and-smart-contract-system',
    color: 'dvColor',
    position: 'right',
    image: 'previewDV',
  },
]

export default SuccessStoriesSlider
