// extracted by mini-css-extract-plugin
export var discoverWorksItem = "cY_ws";
export var discoverWorksItem__arrowLink = "cY_wG";
export var discoverWorksItem__bg = "cY_ww";
export var discoverWorksItem__clientImg = "cY_wK";
export var discoverWorksItem__clientName = "cY_wL";
export var discoverWorksItem__clientPosition = "cY_wM";
export var discoverWorksItem__gradientAfricar = "cY_w0";
export var discoverWorksItem__gradientBabel = "cY_w3";
export var discoverWorksItem__gradientCaker = "cY_wT";
export var discoverWorksItem__gradientDv = "cY_wZ";
export var discoverWorksItem__gradientImpact = "cY_wP";
export var discoverWorksItem__gradientIx = "cY_wY";
export var discoverWorksItem__gradientMalleni = "cY_wQ";
export var discoverWorksItem__gradientPmk = "cY_wW";
export var discoverWorksItem__gradientPmkDesktop = "cY_wX";
export var discoverWorksItem__gradientRew = "cY_wN";
export var discoverWorksItem__gradientTimebot = "cY_w2";
export var discoverWorksItem__gradientTradeaboat = "cY_wR";
export var discoverWorksItem__gradientTradervs = "cY_wS";
export var discoverWorksItem__gradientVaria = "cY_w1";
export var discoverWorksItem__gradientWowner = "cY_wV";
export var discoverWorksItem__header = "cY_wt";
export var discoverWorksItem__imageHover = "cY_wv";
export var discoverWorksItem__mobileImage = "cY_wH";
export var discoverWorksItem__results = "cY_wz";
export var discoverWorksItem__results_head = "cY_wB";
export var discoverWorksItem__results_num = "cY_wC";
export var discoverWorksItem__results_text = "cY_wD";
export var discoverWorksItem__results_textOnly = "cY_wF";
export var discoverWorksItem__review = "cY_wJ";
export var discoverWorksItem__title = "cY_wx";
export var discoverWorksItem__wrapper = "cY_wy";